import { VoidFunctionComponent } from 'react'

import Debug from 'components/Debug'
import Canvas from 'components/Canvas'

const RaidEditor: VoidFunctionComponent = () => {
  return (
    <main>
      <h2>welcome to the cool raid diagram editor</h2>
      <Canvas />
      <Debug />
    </main>
  )
}

export default RaidEditor
