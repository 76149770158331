import create from 'zustand'
import { persist } from 'zustand/middleware'

import { Config, RaidHistoryItem } from 'types'
import { DEFAULT_CONFIG } from './constants'

interface Store {
  config: Config
  history: RaidHistoryItem[]
  loadConfig: (newConfig: Config) => void
  addHistoryItem: (newHistoryItem: RaidHistoryItem) => void
}

const useStore = create<Store>(
  persist(
    (set, get) => ({
      config: DEFAULT_CONFIG,
      history: [],

      loadConfig: (newConfig) => {
        set({ config: newConfig })
      },

      addHistoryItem: (newHistoryItem) => {
        /*
         * old history is just called history. new history is
         * the future. makes u think
         */
        const oldHistory = get()
          .history.filter((item) => item.id !== newHistoryItem.id)
          .slice(0, 4)

        set({ history: [newHistoryItem, ...oldHistory] })
      }
    }),
    { name: 'raid' }
  )
)

export default useStore
