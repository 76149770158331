import { Job, Config } from 'types'

export const DEFAULT_CONFIG: Config = {
  backgrounds: [],
  aoes: [],
  players: [],
  directions: []
}

export const JOBS: Job[] = [
  [['D3', 'R1', 'BRD', 'Bard'], 'img/03_DPS/Job/Bard.png'],
  [['BLM', 'Black Mage'], 'img/03_DPS/Job/BlackMage.png'],
  [['DNC', 'Dancer'], 'img/03_DPS/Job/Dancer.png'],
  [['DRG', 'Dragoon'], 'img/03_DPS/Job/Dragoon.png'],
  [['MCH', 'Machinist'], 'img/03_DPS/Job/Machinist.png'],
  [['MNK', 'Monk'], 'img/03_DPS/Job/Monk.png'],
  [['D1', 'M1', 'NIN', 'Ninja'], 'img/03_DPS/Job/Ninja.png'],
  [['D2', 'M2', 'RPR', 'Reaper'], 'img/03_DPS/Job/Reaper.png'],
  [['D4', 'R2', 'RDM', 'Red Mage'], 'img/03_DPS/Job/RedMage.png'],
  [['SAM', 'Samurai'], 'img/03_DPS/Job/Samurai.png'],
  [['SMN', 'Summoner'], 'img/03_DPS/Job/Summoner.png'],
  [['MT', 'DRK', 'Dark Knight'], 'img/01_TANK/Job/DarkKnight.png'],
  [['GNB', 'Gunbreaker'], 'img/01_TANK/Job/Gunbreaker.png'],
  [['OT', 'PLD', 'Paladin'], 'img/01_TANK/Job/Paladin.png'],
  [['WAR', 'Warrior'], 'img/01_TANK/Job/Warrior.png'],
  [['H2', 'AST', 'Astrologian'], 'img/02_HEALER/Job/Astrologian.png'],
  [['SGE', 'Sage'], 'img/02_HEALER/Job/Sage.png'],
  [['H1', 'SCH', 'Scholar'], 'img/02_HEALER/Job/Scholar.png'],
  [['WHM', 'White Mage'], 'img/02_HEALER/Job/WhiteMage.png'],
  [['BLU', 'Blue Mage'], 'img/06_LIMITED/BlueMage.png'],
  [['Tank'], 'img/00_ROLE/TankRole.png'],
  [['DPS'], 'img/00_ROLE/DPSRole.png'],
  [['Healer'], 'img/00_ROLE/HealerRole.png']
]
