import { VoidFunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { useGoogleProfile } from 'modules/Auth'

const Header: VoidFunctionComponent = () => {
  const profile = useGoogleProfile()

  return (
    <header className="o-section c-header">
      <h1>
        <Link to="/">cool raid stuff</Link>
      </h1>

      <div className="c-header__profile">
        {profile ? (
          <div className="c-profile">
            <img
              alt=""
              className="c-profile__image"
              src={profile.getImageUrl()}
            />
            <span className="c-profile__name">{profile.getName()}</span>
          </div>
        ) : (
          <span>You&apos;re are not logge in</span>
        )}
      </div>
    </header>
  )
}

export default Header
