/* this is the file where we hide all the google api dragons */

const GAPI_SCRIPT_URL = 'https://apis.google.com/js/api.js'

// Client ID and API key from the Developer Console
const CLIENT_ID =
  '473561412097-o6ijk9t94bggh1ih8dkqnbgmhmp6kmh5.apps.googleusercontent.com'
const API_KEY = 'AIzaSyBBXFsHK7AAdFjpnjpI1SNvh5L1RPyX_0E'

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
]

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/drive.file'

let gapiLoader: Promise<void>
let gapiAuthLoader: Promise<void>
let gapiInitialiser: Promise<void>

const loadGAPIScript = () => {
  if (window.gapi) {
    return Promise.resolve()
  }

  if (!gapiLoader) {
    gapiLoader = new Promise((resolve, reject) => {
      const gapiScript = document.createElement('script')
      gapiScript.async = true
      gapiScript.defer = true
      gapiScript.src = GAPI_SCRIPT_URL

      gapiScript.onload = () => resolve()
      gapiScript.onerror = () => reject('Failed to load GAPI script')

      document.head.appendChild(gapiScript)
    })
  }

  return gapiLoader
}

const loadGAPIAuthLibrary = () => {
  if (window.gapi.auth2) {
    return Promise.resolve()
  }

  if (!gapiAuthLoader) {
    gapiAuthLoader = new Promise((resolve) => {
      gapi.load('client:auth2', resolve)
    })
  }

  return gapiAuthLoader
}

const initialiseGAPIClient = () => {
  if (!gapiInitialiser) {
    gapiInitialiser = window.gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES
    })
  }

  return gapiInitialiser
}

const getGAPIInstance = async () => {
  await loadGAPIScript()
  await loadGAPIAuthLibrary()
  await initialiseGAPIClient()

  return window.gapi
}

/*
 * start to load the many different parts of GAPI as soon
 * as the app starts, rather than waiting for the first
 * time a query is called
 */
getGAPIInstance()

export default getGAPIInstance
