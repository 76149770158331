import React, { useCallback, useState, VoidFunctionComponent } from 'react'
import useStore from 'store'

const Debug: VoidFunctionComponent = () => {
  const { config, loadConfig } = useStore()
  const [output, setOutput] = useState('')

  const onChangeOutput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setOutput(event.target.value)
    },
    [setOutput]
  )

  const onExportConfig = useCallback(() => {
    setOutput(JSON.stringify(config, undefined, 2))
  }, [config, setOutput])

  const onImportConfig = useCallback(() => {
    /* imagine this was a cool function that validated it as a Config */
    const newConfig = JSON.parse(output)
    loadConfig(newConfig)
  }, [output, loadConfig])

  return (
    <section className="o-section">
      <div>
        <textarea
          rows={10}
          cols={50}
          onChange={onChangeOutput}
          value={output}
        />
      </div>

      <button onClick={onImportConfig}>Load into diagram</button>
      <button onClick={onExportConfig}>Save from diagram</button>
    </section>
  )
}

export default Debug
