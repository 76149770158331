import { VoidFunctionComponent, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Canvas from 'components/Canvas'
import useRaidQuery from './useRaidQuery'
import useStore from 'store'

const RaidViewer: VoidFunctionComponent = () => {
  const { addHistoryItem } = useStore()

  const { id } = useParams()
  const { isLoading, error, data } = useRaidQuery(id!)

  useEffect(() => {
    if (!data) {
      return
    }

    addHistoryItem({
      id: id!,
      name: data.name!,
      author: 'dani i guess'
    })
  }, [data, id, addHistoryItem])

  if (isLoading) {
    return <div>Loading!!!!!!</div>
  }

  if (error) {
    return <div>i couldnt load it.... :(</div>
  }

  return (
    <main>
      <h2>viewing raid {id}</h2>
      <Canvas />
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </main>
  )
}

export default RaidViewer
