import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import RaidApp from './RaidApp'
import GoogleProvider from 'modules/Auth'

import 'style/index.scss'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleProvider>
        <RaidApp />
      </GoogleProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
