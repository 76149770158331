import { VoidFunctionComponent } from 'react'
import { Stage, Layer, Image, Ring, Circle } from 'react-konva'
import useImage from 'use-image'

import useStore from 'store'
import { JOBS } from '../../constants'
import { AOE, Player, Background } from 'types'

const RING_STYLE = {
  stroke: 'black',
  strokeWidth: 2,
  opacity: 0.8,
  fillRadialGradientStartPoint: { x: 0, y: 0 },
  fillRadialGradientStartRadius: 0,
  fillRadialGradientEndPoint: { x: 0, y: 0 },
  fillRadialGradientColorStops: [0, 'yellow', 0.7, 'yellow', 1, 'orange']
}

const ImageElement: VoidFunctionComponent<any> = ({ n, u, x, y, t }) => {
  const [image] = useImage(u)

  const position =
    t === 'f' ? { x: 0, y: 0, width: 750, height: 750 } : { x, y }

  return <Image draggable image={image} name={n} {...position} />
}

const getJob = (toFind: string) => {
  const job = JOBS.find(([jobKeys]) =>
    jobKeys.some((key) => toFind.toUpperCase() === key.toUpperCase())
  )

  return job ? job[1] : 'http://placekitten.com/100/100'
}

const PlayerElement: VoidFunctionComponent<Player> = ({ n, u, x, y }) => {
  const jobIcon = getJob(u)
  const [image] = useImage(jobIcon)

  return (
    <Image
      draggable
      scaleX={0.5}
      scaleY={0.5}
      image={image}
      name={n}
      x={x}
      y={y}
    />
  )
}

const BackgroundElement: VoidFunctionComponent<Background> = (props) => {
  return <ImageElement {...props} />
}

const CircleElement: VoidFunctionComponent<AOE> = ({ n, t, r, x, y }) => {
  /* we'd probaly separate these types eventually to remove the need for weird casting */
  const radius = r as number

  return (
    <Circle
      radius={radius}
      name={n}
      x={x}
      y={y}
      draggable
      fillRadialGradientEndRadius={radius}
      {...RING_STYLE}
    />
  )
}

const DonutElement: VoidFunctionComponent<AOE> = ({ n, t, r, x, y }) => {
  /* we'd probaly separate these types eventually to remove the need for weird casting */
  const radius = r as number[]

  return (
    <Ring
      innerRadius={radius[0]}
      outerRadius={radius[1]}
      name={n}
      x={x}
      y={y}
      draggable
      fillRadialGradientEndRadius={radius[1]}
      {...RING_STYLE}
    />
  )
}

const AOEElement: VoidFunctionComponent<AOE> = (props) => {
  if (Array.isArray(props.r)) {
    return <DonutElement {...props} />
  }

  return <CircleElement {...props} />
}

const Canvas: VoidFunctionComponent = () => {
  const config = useStore((state) => state.config)
  const { backgrounds, aoes, players } = config

  return (
    <section className="o-section">
      <Stage className="c-diagram" width={750} height={750}>
        <Layer>
          {backgrounds.map((background) => (
            <BackgroundElement key={background.n} {...background} />
          ))}
        </Layer>

        <Layer>
          {aoes.map((aoe) => (
            <AOEElement key={aoe.n} {...aoe} />
          ))}
        </Layer>

        <Layer>
          {players.map((player) => (
            <PlayerElement key={player.n} {...player} />
          ))}
        </Layer>
      </Stage>
    </section>
  )
}

export default Canvas
