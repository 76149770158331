import { VoidFunctionComponent } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import Header from 'components/Header'
import Home from 'modules/Home'
import RaidViewer from 'modules/RaidViewer'
import RaidEditor from 'modules/RaidEditor'

const RaidApp: VoidFunctionComponent = () => (
  <div className="c-container">
    <Header />

    <Routes>
      <Route path="/create" element={<RaidEditor />} />

      <Route path="/:id/edit" element={<RaidEditor />} />
      <Route path="/:id" element={<RaidViewer />} />
      <Route path="/" element={<Home />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>

    <footer>baldurk made this web of site</footer>
  </div>
)

export default RaidApp
