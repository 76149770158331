import { VoidFunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import useStore from 'store'

const Home: VoidFunctionComponent = () => {
  const { history } = useStore()

  return (
    <main>
      <header>
        <h1>raid.design</h1>
        <h2>home of the gamer online</h2>
      </header>

      <div className="c-home">
        <section className="c-home__section">
          <h2>Your raids</h2>

          <Link to="/">Create a new raid</Link>
        </section>

        <section className="c-home__section">
          <h2>New to raid.design?</h2>
          <p>Here are some examples to get you started!</p>

          <Link className="c-raid-link" to="/1FuRwG1EE2-8QasqCRpKhQ97A4ouaT_d4">
            <span className="c-raid-link__name">dog doing a line</span>
            <span className="c-raid-link__author">by baldurk</span>
          </Link>
        </section>

        <section className="c-home__section">
          <h2>Recently viewed</h2>

          {!history.length && <p>raids you look at will go here. cool</p>}

          {history.map(({ id, name, author }) => (
            <Link key={id} className="c-raid-link" to={`/${id}`}>
              <span className="c-raid-link__name">{name}</span>
              <span className="c-raid-link__author">{author}</span>
            </Link>
          ))}
        </section>
      </div>
    </main>
  )
}

export default Home
