import { getGAPIInstance } from 'modules/Auth'
import { useEffect, useState } from 'react'

const getRaidConfig = async (id: string) => {
  const gapi = await getGAPIInstance()

  /*
   * we have to trick gapi into thinking it's logged out to load
   * public files because of a weird quirk with how google drive
   * handles permissions--logged-in users can't load publically
   * shared files, for some reason, only files they themselves
   * have been granted access to
   */
  const authToken = gapi.auth.getToken()
  gapi.client.setToken({} as any)

  try {
    const response = await gapi.client.drive.files.get({
      fileId: id,
      alt: 'media'
    })

    return response.body
  } catch (e) {
    console.error('Could not load raid from Drive!', e)
    throw e
  } finally {
    gapi.client.setToken(authToken)
  }
}

interface QueryState {
  data: any | null
  isLoading: boolean
  error: unknown | null
}

const useRaidQuery = (id: string) => {
  const [queryState, setQueryState] = useState<QueryState>({
    data: null,
    isLoading: true,
    error: null
  })

  useEffect(() => {
    const loadQuery = async () => {
      setQueryState((prevState) => ({ ...prevState, isLoading: true }))

      try {
        const data = JSON.parse(await getRaidConfig(id))
        setQueryState({ isLoading: false, data, error: null })
      } catch (error) {
        setQueryState({ isLoading: false, data: null, error })
      }
    }

    loadQuery()
  }, [id, setQueryState])

  return queryState
}

export default useRaidQuery
