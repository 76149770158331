import { FunctionComponent, useState, useEffect, useContext } from 'react'
import { GoogleContext } from './context'
import getGAPIInstance from './gapi'

export const useGoogleProfile = () => useContext(GoogleContext)

const GoogleProvider: FunctionComponent = ({ children }) => {
  const [userProfile, setUserProfile] = useState<gapi.auth2.BasicProfile>()

  useEffect(() => {
    let listener: any
    ;(async () => {
      const gapi = await getGAPIInstance()

      listener = gapi.auth2.getAuthInstance().currentUser.listen((user) => {
        setUserProfile(user.isSignedIn() ? user.getBasicProfile() : undefined)
      })
    })()

    return () => {
      listener?.remove()
    }
  }, [setUserProfile])

  return (
    <GoogleContext.Provider value={userProfile}>
      {children}
    </GoogleContext.Provider>
  )
}

export default GoogleProvider
